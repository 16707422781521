import { EmMaxWidthType, HtmlHeadings } from "types/layout";

import {
  DEFAULT_EM_MAX_WIDTH,
  HtmlHeadingsClasses,
} from "constants/typography";

type TitleProps = {
  children: React.ReactNode;
  className?: string;
  style?: object;
  emMaxWidth?: EmMaxWidthType;
  size?: HtmlHeadings;
};

const Title = ({
  children,
  className = "",
  emMaxWidth,
  style = {},
  size = HtmlHeadings.h1,
}: TitleProps) => {
  const maxWidth = emMaxWidth
    ? emMaxWidth === "default"
      ? DEFAULT_EM_MAX_WIDTH
      : emMaxWidth + "em"
    : null;

  const style_ = { ...(maxWidth ? { maxWidth } : {}), ...style };
  const className_ = [
    "font-black leading-tight",
    HtmlHeadingsClasses[HtmlHeadings[size]],
    className ? className : "",
  ].join(" ");

  // Use the HtmlHeadings enum as a JSX tag name
  const HeadingTag = size;

  return (
    <HeadingTag className={className_} style={style_}>
      {children}
    </HeadingTag>
  );
};

export default Title;
