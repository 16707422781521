export enum HtmlHeadings {
  h1 = "h1",
  h2 = "h2",
  h3 = "h3",
  h4 = "h4",
  h5 = "h5",
  h6 = "h6",
}

/* CSS Aspect-ratio (width / height) */
export enum AspectRatios {
  default = "1.661",
  narrower = "1.663",
  square = "1",
  video = "1.77",
}

export type AspectRatioType = string;
export type EmMaxWidthType = null | "default" | number;

export type LinkType = {
  text: string;
  href: string;
};
