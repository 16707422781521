import { useEffect } from "react";

import { useUIContext } from "contexts/UIContext";

import Section from "components/containers/Section";
import Title from "components/text/Title";

const Custom404 = () => {
  const { setFooterStyle, setNavbarStyle } = useUIContext();

  useEffect(() => {
    setNavbarStyle({
      navbarColor: "navy",
      navbarMobileHamburgerIconColor: "navy",
      navbarTabletHamburgerIconColor: "navy",
      navbarLogo: "dark",
    });
    setFooterStyle({
      showShortFooter: false,
      footerBackgroundColor: "navy",
    });
  }, []);

  return (
    <Section className="pt-32 sm:pt-36 lg:pt-44 xl:pt-48">
      <Title className="text-center">Not found</Title>
      <div className="text-center py-16">
        Sorry, we couldn&apos;t find the page you were looking for
      </div>
    </Section>
  );
};

export default Custom404;
