import { HtmlHeadings } from "types/layout";

export const DEFAULT_EM_MAX_WIDTH = "20em";

export const HtmlHeadingsClasses = {
  [HtmlHeadings.h1]: "text-heading2 lg:text-heading1",
  [HtmlHeadings.h2]: "text-heading2",
  [HtmlHeadings.h3]: "text-heading4",
  [HtmlHeadings.h4]: "",
  [HtmlHeadings.h5]: "",
  [HtmlHeadings.h6]: "",
};
